import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./ui/ErrorFallback";
import { setup } from "./mud/setup";
import { MUDProvider } from "./MUDContext";

import { WagmiProvider } from "wagmi";
import { wagmiConfig } from "./wagmiConfig";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { defineConfig, EntryKitProvider } from "@latticexyz/entrykit/internal";
import { chainId, getWorldAddress, startBlock } from "./common";

import { App } from "./App";

import "./assets/styles/main.css";
import "./assets/styles/cursors.css";

const queryClient = new QueryClient();

setup().then( async (result) => {
  const worldAddress = getWorldAddress();
  createRoot(document.getElementById("react-root")!).render(
    <StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <WagmiProvider config={wagmiConfig}>
          <QueryClientProvider client={queryClient}>
            <EntryKitProvider config={defineConfig({ chainId, worldAddress })}>
              <MUDProvider value={result} >
                <App />
              </MUDProvider>
            </EntryKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </ErrorBoundary>
    </StrictMode>,
  );
})


// if( window.location.search.indexOf('exhibition') !== -1 ){
//   const EXHIB_TIMEOUT_TIME = 3 * 60 * 1000;
//   let exhibTimeout = setTimeout(()=>{}, 1);
//   const updateExhibitionReset = () => {
//     clearTimeout( exhibTimeout );
//     exhibTimeout = setTimeout(() => {
//       window.location = window.location;
//     }, EXHIB_TIMEOUT_TIME );
//   }
//   document.body.addEventListener('mousemove', () => {
//     updateExhibitionReset();
//   });
//   document.body.addEventListener('mousedown', () => {
//     updateExhibitionReset();
//   });
//   document.body.addEventListener('keydown', () => {
//     updateExhibitionReset();
//   });
// }