import { defineWorld } from "@latticexyz/world";

export default defineWorld({
  systems: {
    // PlayerCreationSystem: {
    //   name: "addPlayer",
    //   openAccess: true,
    // },
  },
  tables: {
    Counter: {
      schema: {
        value: "uint32"
      },
      key: []
    },
    StoryCounter: {
      schema: {
        value: "uint32"
      },
      key: []
    },
    ///StorySeed.set("1", "99a9B7c1116f9ceEB1652de04d5969CcE509B069",[]);
    // StorySeed.setChilds("1", [99a9B7c1116f9ceEB1652de04d5969CcE509B069])
    StorySeed: {
      // need to set the key schema here
      schema: {
        counterValue: "uint32",
        // using the hash as a key (?) otherwise number, these are set by the game
        /// using a number form 1-1000 as Seed
        seed: "uint32",
        /// ownership of seed
        ownership: "bytes32",
        /// hash of the seed data
        hash: "bytes32",
        /// is the seed used 1 = yes ; 0 = no
        haschild: "uint32",
        /// speacial field
        specialfield: "uint32",
        /// array of all child hashes - re - story beginnings
        child: "bytes32",
        ///the raw data
        data: "string"
      },
      key: ["counterValue"],
    },

    Player: {
      schema: {
        counterValue: "bytes32",      
        // using the public address as key
        hasWritten: "bool",
        counter: "uint32",
        fraction: "bytes32",
        secret: "uint32",
        wonAt: "uint256",
        name: "string",
        currentSeedNumbers: "uint32[]",
        story: "bytes32[]",
      },
      key: ["counterValue"],
    },

    PlayerTimes: {
      schema: {
        counterValue: "bytes32",
        lastVote: "uint256",
        lastForking: "uint256",
        lastRoll: "uint256",
        lastWriting: "uint256",
      },
      key: ["counterValue"],
    },


    Fraction: {
      schema: {
        counterValue: "bytes32",     
        fractionPower: "uint256",
        fractionName: "string",
        factionEnforcedWords: "uint256[]",
      },
      key: ["counterValue"],
    },

    StoryPartVotingRestrictions: {
      schema: {
        counterValue: "bytes32",
        votedOnBy: "bytes32",
      },
      key: ["counterValue"],
    },

    ForkRestrictions: {
      schema: {
        counterValue: "bytes32",     
        forkedBy: "bytes32",
      },
      key: ["counterValue"],
    },

    IsFork: {
      schema: {
        counterValue: "bytes32", 
        isFork: "bool",
      },
      key: ["counterValue"],
    },

    StoryPart: {
      // using the hash as a key -> kek(player+parenthas / seednumber)
      schema: {
        counterValue: "bytes32",      
        /// using the hash of the parent eg
        parent: "bytes32",
        /// uint of seed data
        orgSeed: "uint32",
        /// author
        author: "bytes32",
        //timer start
        created: "uint256",
        /// faction of the writer
        faction: "bytes32",
        /// ID 
        id: "uint256",
        /// positive Votes
        posVotes: "uint32",
        /// negative Votes
        negVotes: "uint32",
        /// actual story
        data: "string",
      },
      key: ["counterValue"],
    },
  }
});

