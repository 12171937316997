/*
 * The supported chains.
 * By default, there are only two chains here:
 *
 * - mudFoundry, the chain running on anvil that pnpm dev
 *   starts by default. It is similar to the viem anvil chain
 *   (see https://viem.sh/docs/clients/test.html), but with the
 *   basefee set to zero to avoid transaction fees.
 * - latticeTestnet, our public test network.
 *

 */

import { MUDChain, garnet, mudFoundry, pyrope } from "@latticexyz/common/chains";
import { defineChain } from 'viem'
 
// export const pyrope = defineChain({
//   id: 695569,
//   name: 'Pyrope Testnet',
//   nativeCurrency: {
//     decimals: 18,
//     name: 'Ether',
//     symbol: 'ETH',
//   },
//   rpcUrls: {
//     default: {
//       http: ['https://rpc.pyropechain.com'],
//       webSocket: ['wss://rpc.pyropechain.com'],
//     },
//     bundler: {
//       http: ['https://rpc.pyropechain.com'],
//       webSocket: ['wss://rpc.pyropechain.com'],
//     },
//   },
//   blockExplorers: {
//     default: { name: 'Explorer', url: 'https://explorer.pyropechain.com' },
//   }
// })
/*
 * See https://mud.dev/tutorials/minimal/deploy#run-the-user-interface
 * for instructions on how to add networks.
 */
export const supportedChains: MUDChain[] = [mudFoundry, pyrope, garnet];