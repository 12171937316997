import { useState, useMemo, useRef, useEffect } from "react";
import { shuffle, random, words } from "lodash";
import { useGuide } from "../../GuideContext";
import { useMUD } from "../../../MUDContext";
import MainButton from "../../../components/MainButton";

import ExtraAudio from "../../../ExtraAudio.js";

import { textConst, getGuideText, getChronicleTypeComment } from "../../../text";

import { SeedViewer } from "../../../components/SeedViewer";

import  "../../../assets/styles/state-write.css";

const WriteChurch = ({onWriteComplete, shouldRender, useEnforcedWord}) => {
  const {
    network: { tables, useStore, playerEntity }
  } = useMUD();

  const [ writingStage, setWritingStage ] = useState( 0 );
  const [ storyContent, setStoryContent ] = useState('');
  const [ enforcedWord, setEnforcedWord ] = useState( useEnforcedWord );
  const [ storyValidation, setStoryValidation ] = useState({
    word: false,
    isMainValid: false,
    mainSubmitAttempt: false,    
  });
  
  const validateStory = () => {
    const word = storyContent.toLowerCase().indexOf( enforcedWord.toLowerCase() ) > -1;
    const validation = {
      ...storyValidation,
      word,
      isMainValid: !!word,
      mainSubmitAttempt: true
    } 
    if( !validation.isMainValid ){
      setGuideState({
        ...guideState,
        statement: getGuideText( 'Church', textConst.WRITEVALIDATION, [{find: '<mandatoryword>', replace: enforcedWord }] )
      })
    }

    setStoryValidation( validation );
    return validation;
  }

  const formatFinalStory = () => {
    return storyContent;
  }

  const {guideState, setGuideState} = useGuide();
  useEffect(() => { 
    if( shouldRender ){
      setGuideState({
        ...guideState,
        statement: getGuideText( 'Church', textConst.WRITEINSTRUCTION, [{find: '<mandatoryword>', replace: enforcedWord }] )
      })
    }
  },[ shouldRender, enforcedWord ]);

  return <>
    <div className="story-area story-area__church">
      <textarea 
        className="write-input-box"
        value={storyContent} 
        onChange={ (e) => { setStoryContent(e.target.value) } }
        readOnly={writingStage !== 0}
        placeholder="Write here..."
      ></textarea>
      <br></br>
      { ( writingStage < 2 ) 
          ? <MainButton 
            name="Submit story" 
            className="story-submit-button"
            onClick={() => {
              const validation = ( writingStage === 0 ) ? validateStory() : validateAbstract();        
              if( writingStage === 0 && validation.isMainValid ){
                onWriteComplete( formatFinalStory() );
                setWritingStage( 1 );
              }
            }}        
          />
          : ''
      }
      <br></br>
      <br></br>
      {(storyValidation.mainSubmitAttempt && !storyValidation.isMainValid) 
        ? `You must use ${ enforcedWord } in your story`
        : ''
      }
    </div>
  </>
}

const WriteRadical = ({onWriteComplete, shouldRender}) => {
  const [ storyContent, setStoryContent ] = useState('');
  const [ isStoryValid, setIsStoryValid ] = useState(false);
  
  const validateStory = () => {
    const isValid = storyContent.length > 0;
    setIsStoryValid( isValid );
    return isValid;
  }

  const {guideState, setGuideState} = useGuide();

  return <>
    <div className="story-area story-area__radical">
      <textarea 
        className="write-input-box"
        value={storyContent} 
        onChange={ (e) => { setStoryContent(e.target.value) } }       
        placeholder="Write here..."
      ></textarea>    
      <MainButton 
        className="story-submit-button story-submit-button__radical"
        name="Submit Story" 
        onClick={() => {   
          const validation = validateStory();
          if( validation ){
            onWriteComplete( storyContent );
          }
        }}
      />
    </div>
  </> 
}

const WriteWolf = ({onWriteComplete, shouldRender}) => {
  const [ storyContent, setStoryContent ] = useState('');
  const [ isStoryValid, setIsStoryValid ] = useState(false);
  const textAreaRef = useRef();

  const shuffleStoryChunk = ( story ) => {
    const characters = story.split('');
    const chunkLength = random( Math.min(5, characters.length), Math.min( 15, characters.length ) );
    const chunkStart = random( 0, (characters.length - chunkLength) );
    const chunkEnd = chunkStart + chunkLength;
    const chunk = characters.slice( chunkStart, chunkEnd );    
    const shuffled = shuffle( chunk );
    characters.splice( ...[chunkStart, chunkLength].concat(shuffled) );    
    return characters.join('');
  }
  const formatFinalStory = () => {
    return storyContent;
    //return (Math.random() > 0.75) ? shuffleStoryChunk( storyContent ) : storyContent;
  }

  const validateStory = () => {
    const isValid = storyContent.length > 0;
    setIsStoryValid( isValid );
    return isValid;
  }

  const {guideState, setGuideState} = useGuide();

  return <>
    <div className="story-area story-area__wolf">
      <textarea 
        className="write-input-box"
        value={storyContent} 
        onChange={ (e) => { setStoryContent(e.target.value) } }
        onKeyDown={ (e) => { 
          if(e.which == 8 || e.which == 46){
             e.preventDefault();
             setGuideState({
              ...guideState,
              statement: 'No deleting! Better be quick..'
            })
          } 
        }}
        ref={textAreaRef}
        onSelect={ (e) => { textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd }}
        placeholder="Write here..."
      ></textarea>    
      <MainButton 
        className="story-submit-button"
        name="Submit Story" 
        onClick={() => {   
          const validation = validateStory();
          if( validation ){
            const final = formatFinalStory();
            setStoryContent( final );
            onWriteComplete( final );
          }
        }}
      />
    </div>
  </> 
}

const WriteInterface = ({
  seedId,
  shouldRender,
  onWriteComplete=()=>{},
  enforcedWord
}) => {
  const {
    network: { tables, useStore, playerEntity },
    systemCalls: { addStory }
  } = useMUD();

  const {guideState, setGuideState} = useGuide();

  const [writeComplete,setWriteComplete] = useState(false);

  const username = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    return player.name;
  });
  const playerFaction = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    const faction = getValue(tables.Fraction, {counterValue: player.fraction});
    return faction.fractionName;
  });

  useEffect( () => {
    if( writeComplete ){
      if( shouldRender ){
        setGuideState({
          ...guideState,
          statement: getGuideText( playerFaction, textConst.WRITEPUBLISH, [{find: '<username>', replace: username }] )
        })
      }
      const handle = setTimeout( () => { 
        if( !shouldRender ) return;
        setWriteComplete( false );
        onWriteComplete();
      }, 5000)
      return () => {
        clearTimeout(handle);
      }
    }
  }, [writeComplete])

  useEffect( () => {
    if( !writeComplete && shouldRender ){
      let handle;   
      const handleDelay = setTimeout( () => {
        handle = setInterval( () => { 
          if( !shouldRender ) return;
          setGuideState({
            ...guideState,
            statement: getGuideText( playerFaction, textConst.CREATIVEPROMPTSWRITING, [{find: '<username>', replace: username }] )
          })
        }, 60000);
      }, 30000 );  
      
      return () => {
        clearInterval(handle);
        clearTimeout( handleDelay );
      }
    }
  }, [writeComplete, shouldRender])
  
  return <>
    <div className="game-state game-state__write">
      <div className="write-interface">
        <SeedViewer seedId={seedId}/>
        {(playerFaction === 'Church' ) 
          ? <WriteChurch 
              onWriteComplete={( storyContent ) => {
                addStory( storyContent );
                onWriteComplete();
              }} 
              shouldRender={shouldRender}
              useEnforcedWord={enforcedWord}
            />
          : '' 
        }
        {(playerFaction === 'Radical') 
          ? <WriteRadical 
              onWriteComplete={( storyContent ) => {
                addStory( storyContent );                
                onWriteComplete();              
              }} 
              shouldRender={shouldRender}
            />
          : '' 
        }
        {(playerFaction === 'Wolf') 
          ? <WriteWolf 
              onWriteComplete={( storyContent ) => {
                addStory( storyContent );
                onWriteComplete();
              }}
              shouldRender={shouldRender} 
            />
          : '' 
        }
      </div>
    </div>
  </>
}

export const StateWrite = ({
  shouldRender,
  onWriteComplete = () => {} 
}) => {
  const {
    network: { tables, useStore, playerEntity },    
  } = useMUD();

  const player = useStore( ({ getValue }) => {
    return getValue(tables.Player, {key: playerEntity});    
  });

  const playerFaction = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    const faction = getValue(tables.Fraction, {counterValue: player.fraction});
    return faction.fractionName;
  });

  const factionWords = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    const faction = getValue(tables.Fraction, {counterValue: player.fraction});
    return [ 'curse', 'sin', 'purge', 'heretic', 'salvation', 'fire', 'fear', 'burning', 'demon', 'devil' ];
    /* TODO: use the actual faction words saved on chain */
    return faction.factionEnforcedWords;
  });

  const [enforcedWord, setEnforcedWord] = useState( shuffle( factionWords )[0] );

  const {guideState, setGuideState} = useGuide();
  useEffect(() => { 
    if( shouldRender ){   
      setGuideState({
        ...guideState,
        statement: (!player.currentSeedNumbers[0]) 
          ? getGuideText( playerFaction, textConst.WRITEGOROLL ) 
          : [
              getChronicleTypeComment( player.currentSeedNumbers[0] ),
              getGuideText( playerFaction, textConst.WRITEINSTRUCTION, [{find: '<mandatoryword>', replace: enforcedWord}] )
            ].join('\n\n')
      })
    }
  },[ shouldRender, playerFaction, player ]);

  useEffect(() => {
    setEnforcedWord( shuffle( factionWords )[0] );
  }, [player.currentSeedNumbers[0]] )

  return <>
    <div 
      className="game-state game-state__write" 
      style={{
        display: (shouldRender) ? 'block' : 'none'
      }}
    >
      {(player.currentSeedNumbers[0]) 
        ? <WriteInterface 
          onWriteComplete={ () => {
            ExtraAudio.sfxSample('pin');
            onWriteComplete();
          }}
          seedId={player.currentSeedNumbers[0]}
          shouldRender={shouldRender}
          enforcedWord={enforcedWord}
        /> 
        : ''
      }
    </div>
  </>
}